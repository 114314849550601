// 引入阿里云日志SDK
import SlsTracker from '@aliyun-sls/web-track-browser'
import { opts } from '@/utils/dictionary.js'
import { mapGetters } from "vuex";
export const recordMixin = {
	data() {
		return {
			record: [],     // 用户操作记录
			tracker: null,  // 阿里云日志服务
			opts: opts,     // 阿里云日志配置
		}
	},
	computed: {
		...mapGetters(['getUserInfo']),
	},
	mounted() {
		this.tracker = new SlsTracker(this.opts)
	},
	methods: {
		/**
		* 添加操作记录 
		* type:操作类型 (System:系统操作,Click:用户点击,Input:用户输入,Refer:用户查阅协议)
		* major:操作描述
		* minor:操作详情
		*/
		onRecord(type,major,minor='') {   
			let that = this
			let record = {
				time: new Date().getTime(),
				type,
				major,
				minor
			}
			// console.log('添加日志记录',record)
			this.record.push(record)
		},
		putRecord(orderId) {
			let params = {
				orderId: orderId,
				openId: this.getUserInfo.openId,
				logStr: JSON.stringify(this.record)
			}
			console.log('提交日志',params)
			this.tracker.sendImmediate(params)
		},
	}
}